import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanDeactivate, CanMatch, Route, RouterStateSnapshot, UrlSegment, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {CookieService} from "ngx-cookie-service";
import {AuthService} from "../services/auth.service";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate, CanActivateChild, CanDeactivate<unknown>, CanMatch {

  public sso_signed: any = this.cookieService.get('sso_signed');

  constructor(private router: Router, private cookieService: CookieService, private authService: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    const token = this.authService.getToken;

    if (token) {
      if (/^\/(login|register)/.test(state.url)) {
        window.location.href = environment.vettimesUrl;
        return false;
      }

      if (state.url.startsWith('/admin') && currentUser.role !== 'Admin') {
        return this.router.parseUrl('/');
      }

      return true;
    }

    if (['/admin', '/subscription', '/professional-details'].some(url => state.url.startsWith(url))) {
      return this.router.parseUrl('/login');
    }

    return true;
  }


  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canDeactivate(component: unknown, currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canMatch(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }
}
